// src/App.js
import React from "react";
import ArtPortfolio from "./components/ArtPortfolio";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./components/About";
import Layout from "./components/Layout";
import Links from "./components/Links";
import Work from "./components/Work";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <ArtPortfolio />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout>
              <About />
            </Layout>
          }
        />
        <Route
          path="/links"
          element={
            <Layout>
              <Links />
            </Layout>
          }
        />

        <Route
          path="/work/:id"
          element={
            <Layout>
              <Work />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
