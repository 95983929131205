import React from "react";
import { useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  // console.log(pathnames)
  return (
    <>
      <div className="border-b border-dashed border-secondary mb-12">
        <nav className="flex mb-4 ">
          {/* <Link to="/" className="text-blue-500 hover:underline">
            Home
          </Link> */}
          {pathnames.map((value, index) => {
            const to = `/${pathnames.slice(0, index + 1).join("/")}`;

            return (
              <span
                key={to}
                className="text-15px md:text-14px  xl:text-15px 2xl:text-20px font-sourceCode tracking-normal md:tracking-0.23 text-primary text-right mb-4"
              >
                {/* <span className="mx-2">/</span> */}
                {/* <Link to={to} className="text-blue-500 hover:underline"> */}
                {value.charAt(0).toUpperCase() + value.slice(1)}
                {/* </Link> */}
              </span>
            );
          })}
        </nav>
      </div>
    </>
  );
};

export default Breadcrumbs;
