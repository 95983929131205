import React, { useEffect, useState } from "react";
import ProjectDetail from "./ProjectDetail";
import { useParams } from "react-router-dom";

const Work = () => {
  const [project, setProject] = useState(null);
  const [totalWorks, setTotalWorks] = useState(null);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetch("/data.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTotalWorks(data.length);
        const item = data.find((item) => item.id.toString() === id.toString());

        if (!item) {
          throw new Error(`Item with id ${id} not found`);
        }
        setProject(item);
      })
      .catch((error) => setError(error.message));
  }, [id]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ProjectDetail totalWorks={totalWorks} project={project} />
    </>
  );
};

export default Work;
