import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import { MdOutlineMenu } from "react-icons/md";

const Layout = ({ children }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  // console.log(pathnames, "pathnames");

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="container mx-auto px-4  py-8  flex flex-col md:flex-row">
        <div className="w-full md:w-1/4 md:fixed  md:right-0 md:top-0 h-auto md:h-screen flex flex-col items-center md:p-4 md:pt-6 ">
          <div className="w-full flax flex-wrap">
            <Link
              to="/"
              className="text-14px md:text-14px xl:text-15px 2xl:text-20px font-sourceCode tracking-normal md:tracking-0.23 text-secondary text-right mb-4"
            >
              EMDADUL HOQUE TOPU / এমদাদুল হক তপু
            </Link>
            <button
              onClick={toggleMenu}
              className="md:hidden text-primary text-15px mx-2 mb-4 focus:outline-none"
            >
              <MdOutlineMenu />
            </button>
          </div>
          {isOpen && (
            <nav className="flex flex-col text-primary text-14px md:text-14px tracking-normal md:tracking-0.23">
              <Link to="/about" className="hover:underline my-2">
                About
              </Link>
              <Link to="/links" className="hover:underline my-2">
                Links
              </Link>
              <Link to="/" className="hover:underline my-2">
                Work
              </Link>
            </nav>
          )}
          <nav className="hidden md:flex flex-col text-primary text-14px tracking-normal">
            <Link to="/about" className="hover:underline my-2">
              About
            </Link>
            <Link to="/links" className="hover:underline my-2">
              Links
            </Link>
            <Link to="/" className="hover:underline my-2">
              Work
            </Link>
          </nav>
        </div>

        <div className="w-full md:w-3/4">
          {pathnames.length > 0 && pathnames[0] !== "work" && <Breadcrumbs />}
          {children}
        </div>
      </div>
    </>
  );
};

export default Layout;
