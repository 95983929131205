import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
let array = [
  {
    title: "Research Articles",
    subtitle: "Academia Profile",
    link: "https://jnu.academia.edu/EmdadulHoqueTopu",
  },
  {
    title: "Photography",
    subtitle: "Flickr Profile",
    link: "https://www.flickr.com/photos/emdadul_hoque_topu/",
  },
  {
    title: `Art Curation & <br /> Design Social Media`,
    subtitle: "Behance Profile",
    link: "https://www.behance.net/topuhaq",
  },

  {
    title: "Film Making",
    subtitle: "YouTube Account",
    link: "https://www.youtube.com/@topuhaq1",
  },
  {
    title: "Social Media",
    subtitle: "Instagram Profile",
    link: "https://www.instagram.com/topu.haq/",
  },
];

const Links = () => {
  return (
    <>
      <div className="flex justify-center items-center min-h-fit">
        <div className="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-2 gap-16 p-4">
          {array.map((item, index) => (
            <div className="text-left">
              <Link
                to={item.link}
                target="_blank"
                className="font-sourceCode tracking-2.98 text-primary font-bold  2xl:text-15px"
              >
                {parse(item.title)}
              </Link>

              <Link
                to={item.link}
                target="_blank"
                className="font-sourceCode  text-secondary lg:text-14px "
              >
                <p className="mt-4">{item.subtitle}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Links;
