// src/ArtPortfolio.js

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageModal from "./ImageModal";

const ArtPortfolio = () => {
  const [projects, setProjects] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const handleImageClick = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  useEffect(() => {
    fetch("/data.json")
      .then((response) => response.json())
      .then((data) => setProjects(data))
      .catch((error) => console.error("Error fetching the data:", error));
  }, []);

  if (!projects) {
    return <div>Loading...</div>;
  }

  const cutStringByWords = (text, startWord, endWord) => {
    const words = text.split(" "); // Split the string by spaces to get an array of words
    const slicedWords = words.slice(startWord, endWord); // Get words from 'startWord' to 'endWord'
    return slicedWords.join(" "); 
  };

  return (
    <>
      <div className="w-full">
        {projects.map((project, index) => (
          <div
            key={project.id}
            className="mb-8 flex flex-col md:flex-nowrap lg:flex-row border-b border-dashed border-secondary"
          >
            <div className="w-full">
              {/* <Link to={`/work/${project.id}`}> */}
              <img
                src={`/images/${project.cover_image}`}
                alt={project.title}
                className="w-full h-auto mb-4 cursor-zoom-in"
                onClick={() =>
                  handleImageClick({
                    src: project.cover_image,
                    title: project.title,
                  })
                }
              />
              {/* </Link> */}
            </div>
            {modalImage && (
              <ImageModal
                src={modalImage.src}
                alt={modalImage.title}
                onClose={closeModal}
              />
            )}
            <div className="w-full mx-auto px-4 lg:px-4 md:px-0">
              <Link
                to={`/work/${project.id}`}
                className="font-sourceCode tracking-0.23  lg:font-600 font-normal md:text-20px  xl:text-15px 2xl:text-20px leading-25 mb-4 md:mb-12 text-primary"
              >
                {index + 1}.{project.title} {project.subtitle}
              </Link>
              <p className="mt-2 tracking-1 font-sourceCode  text-secondary  lg:text-14px 2xl:text-15px">
                {/* {project.content.substring(0, 250)} */}
                {cutStringByWords(project?.content, 0, 40)}
              </p>
              <div className="my-4">
                <Link
                  to={`/work/${project.id}`}
                  className="text-secondary text-12px underline mt-2 block"
                >
                  Read more
                </Link>
              </div>
            </div>
            <div className="w-full text-center font-900 md:font-400 text-secondary text-12px">
              <p className="mb-4 md:mb-12">{project.type}</p>
              <p className="">{project.year}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ArtPortfolio;
