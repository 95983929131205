import React from "react";

const About = () => {
  return (
    <div>
      <p className="lg:text-14px 2xl:text-15px font-sourceCode tracking-normal md:tracking-0.23 text-secondary">
        Topu’s work delves into the interplay between colonial history, cultural
        diversity, and environmental concerns. His installations and projects
        blend archival materials, historical texts, and contemporary theories to
        reveal the effects of colonial dominance on South Asian Indigenous
        traditions. Through his practice, he investigates the balance between
        cultural heritage and modernity, emphasizing the importance of
        preserving endangered Indigenous cultures.
        <br /> <br /> Engaged with global issues, Topu’s approach involves
        long-term immersion, collaboration with communities, and exploration of
        historical contexts. This allows him to present layered, sensitive, and
        contradictory narratives on topics such as climate change, migration,
        and state violence. <br /> <br /> Topu’s work has been showcased in
        various international exhibitions and projects. Notably, he participated
        in Documenta Fifteen (2021-22) as an art researcher and assistant
        curator, supported by the Goethe Institute and Britto Arts Trust. In
        2023, he was selected for the R.J.M. museum’s Leaky Archive digital
        fellowship program and commissioned for the “Artist Make Space” (2022)
        collaborative art exhibition project by the British Council U.K. His
        involvement in developing the “Britto Archive” project with the Art
        South Asia Project has been particularly rewarding. <br /> <br /> In
        addition to research-focused projects, Topu has extensive experience
        curating commercial art exhibitions. He has collaborated with the Bengal
        Foundation on projects for the Dhaka Stock Exchange and JTI and won the
        first award for the Pasch-partner schools redesign award by
        Goethe-Institute in 2022. His curation of the Fearless Call art
        exhibition in 2023 and 2024 was a significant public art event,
        providing him with valuable experience in working with diverse
        materials, spaces, and industrial technologies. <br /> <br /> Topu’s
        academic background underpins his professional achievements. He earned
        his Master of Arts in Arts and Aesthetics from Jawaharlal Nehru
        University in 2019 and completed his Bachelor of Fine Arts in History of
        Art from the Faculty of Fine Arts at the University of Dhaka in 2015.
        These credentials support his commitment to creating informed,
        critically engaged curatorial practices. <br /> <br /> Topu envisions a
        future where interdisciplinary collaboration and accessible archives
        foster free thinking and critical analysis of post-Anthropocene and
        postcolonial art history. He aims to disrupt traditional engagement with
        visual archives, reframing historical narratives and challenging
        ritualistic performances often associated with such collections. His
        dedication to inclusive and collaborative curatorial practices seeks to
        enrich the global art discourse and build meaningful relationships
        within the art community. <br /> <br /> Topu continues to push
        boundaries and challenge conventions in his field, working towards a
        more inclusive and critically engaged art world.
      </p>
      <br />
    </div>
  );
};

export default About;
