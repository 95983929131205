const ImageModal = ({ src, alt, onClose }) => {
  return (
    <div
      className="fixed  inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <button
        className="absolute top-0 right-0 m-2 text-white text-2xl"
        onClick={onClose}
      >
        &times;
      </button>
      <div className="relative mx-auto w-10/12 h-auto z-50 ">
        <img
          src={`/images/${src}`}
          alt={alt}
          className="max-w-full max-h-full cursor-zoom-out"
        />
      </div>
    </div>
  );
};

export default ImageModal;
